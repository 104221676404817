// Footer.js
import React from 'react';
import '../styles/Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="quick-links">
          <a href="#hero">Home</a>
          <a href="#aboutus">About Us</a>
          <a href="#pricing">Pricing</a>
          <a href="#contact">Contact Us</a>
        </div>
        <div className="social-media">
          {/* <a href="#" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin"></i></a> */}
          <a href="https://www.tiktok.com/@konnecttaps" target="_blank" rel="noopener noreferrer"><i className="fab fa-tiktok"></i></a>
          <a href="https://www.instagram.com/konnecttaps/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
        </div>
        <p>© 2023 - 2025 KonnectTaps. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;